<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-full">
        <div v-if="isLoading">
          <Loading />
        </div>
        <div :class="stubbed ? 'box block' : ''" v-else>
          <h1 class="title">TICKETS</h1>
          <h1 class="subtitle">Page: {{page}}</h1>
          <p class="panel-tabs">
            <a :class="isStatusActive(null) ? 'is-active' : ''" @click="getAll">All</a>
            <a :class="isStatusActive('Open') ? 'is-active' : ''" @click="getOpen">
              Open
            </a>
            <a :class="isStatusActive('Processing') ? 'is-active' : ''" @click="getProcessing">
              Under Processing
            </a>
            <a :class="isStatusActive('Closed') ? 'is-active' : ''" @click="getClosed">
              Closed
            </a>
          </p>
          <div v-for="(ticket, index) in tickets" :key="ticket.id">
              <b-collapse
                v-if="ticket.agentId"
                :aria-id="ticket.id"
                :class="getClassByStatus(ticket.status)"
                animation="slide"
                :key="index"
                :open="isOpen == index"
                @open="isOpen = index"
              >
                <template #trigger>
                  <div class="columns has-text-left">
                  <div class="column is-1">
                    <span class="tag is-primary">{{ getAgentById(ticket.agentId) }}</span>
                  </div>
                  <div class="column is-1">
                    <span>{{ ticket.status }}</span>
                  </div>
                  <div class="column is-7">
                    {{ ticket.comment }}
                  </div>
                  <div class="column is-2">
                    <span class="is-pulled-right">
                      <b-icon class="mr-1" icon="clock"></b-icon>
                      <b>{{ new Date(ticket.createdAt).toDateString() }}</b>
                      <br>
                      at {{ new Date(ticket.createdAt).toLocaleTimeString() }}
                    </span>
                  </div>
                  <div class="column is-1">
                    <span
                      v-on:click.stop="
                        () => navigateToOrder(ticket.orderId)
                      "
                      class="mx-2 icon"
                    >
                      <b-icon icon="external-link"></b-icon>
                    </span>
                  </div>
                </div>
                </template>
                <footer class="card-footer mt-4">
                <a v-on:click="closeTicket(ticket.id)"
                v-if="ticket.status !== 'Closed'"
                class="card-footer-item has-background-success-light has-text-info">
                <span class="icon">
                  <i class="fas fa-check"></i>
                </span>
                  Mark as Closed
                </a>
                <a v-on:click="underProcess(ticket.id)"
                v-if="ticket.status !== 'Processing'"
                class="card-footer-item has-background-warning-light has-text-danger">
                <span class="icon">
                  <i class="fas fa-sync"></i>
                </span>
                  Under Processing
                </a>
                <a v-on:click="navigateToOrder(ticket.orderId)"
                class="card-footer-item">
                <span class="icon">
                  <i class="fas fa-external-link-alt"></i>
                </span>
                  Navigate To Order
                </a>
            </footer>
              </b-collapse>
          </div>
          <div v-if="!stubbed" class="is-flex-direction-row mt-6">
            <b-button
              class="is-justify-content-flex-start"
              @click="decrementPage"
              :disabled="page === 1"
              >Prev</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateTicketViewPage(1);
                  queryTickets();
                }
              "
              >1</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateTicketViewPage(5);
                  queryTickets();
                }
              "
              >5</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateTicketViewPage(10);
                  queryTickets();
                }
              "
              >10</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateTicketViewPage(20);
                  queryTickets();
                }
              "
              >20</b-button
            >
      <b-button class="is-justify-content-flex-end" @click="incrementPage"
        >Next</b-button
      >
    </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import { mapActions, mapState } from 'vuex';

import { getAgentById, navigateToOrder, createSnackbar } from '@/common';

export default {
  data: () => ({
    isLoading: true,
    isOpen: 0,
  }),
  props: {
    stubbed: Boolean,
  },
  methods: {
    ...mapActions(['queryTickets', 'setTicketsViewFilter', 'updateTicketStatus', 'updateTicketViewPage']),
    getAgentById,
    navigateToOrder,
    createSnackbar,
    isStatusActive(status) {
      return this.filter === status;
    },
    async decrementPage() {
      this.updateTicketViewPage('DECREMENT');
      this.isLoading = true;
      try {
        await this.queryTickets();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async incrementPage() {
      this.updateTicketViewPage('INCREMENT');
      this.isLoading = true;
      try {
        await this.queryTickets();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getAll() {
      this.setTicketsViewFilter(null);
      this.isLoading = true;
      try {
        await this.queryTickets();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getOpen() {
      this.setTicketsViewFilter('Open');
      this.isLoading = true;
      try {
        await this.queryTickets();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getProcessing() {
      this.setTicketsViewFilter('Processing');
      this.isLoading = true;
      try {
        await this.queryTickets();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getClosed() {
      this.setTicketsViewFilter('Closed');
      this.isLoading = true;
      try {
        await this.queryTickets();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    getClassByStatus(status) {
      let backgroundColorStatus = '';
      switch (status) {
        case 'Open':
          backgroundColorStatus = 'has-background-danger-light';
          break;
        case 'Closed':
          backgroundColorStatus = 'has-background-success-light';
          break;
        default:
          backgroundColorStatus = 'has-background-warning-light';
          break;
      }
      return `card mt-5 p-4 ${backgroundColorStatus}`;
    },
    async closeTicket(id) {
      await this.updateTicketStatus({
        id,
        status: 'Closed',
      });
    },

    async underProcess(id) {
      await this.updateTicketStatus({
        id,
        status: 'Processing',
      });
    },
  },
  async mounted() {
    try {
      await this.queryTickets();
    } catch (error) {
      console.error('Error while loading tickets', error);
    } finally {
      this.isLoading = false;
    }
  },
  components: {
    Loading,
  },
  computed: {
    ...mapState({
      tickets: ({ ticketsView: { tickets } }) => tickets,
      filter: ({ ticketsView: { filter } }) => filter,
      page: ({ ticketsView: { page } }) => page,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
  },
};
</script>
